import React from 'react';

import FormButton from 'components/FormButton/FormButton';
import InputField from 'components/InputField/InputField';
import Container from 'components/Container/Container';

import { emailValidation } from 'utils';
import UserService from 'utils/userService';
import { Screens, INVALID_EMAIL_ERROR } from 'containers/constants';

import style from './ChangePassword.module.scss';

interface IProps {
    email?: string;
    code?: string;
    handleScreen: Function;
}

interface IState {
    isLoading: boolean;
    showConfirmation: boolean;
    email: string;
    code: string;
    password: string;
    responseError: string;
}

export default class ForgotPassword extends React.Component<IProps, IState> {
    state = {
        isLoading: false,
        showConfirmation: false,
        email: this.props.email || '',
        code: this.props.code || '',
        password: '',
        responseError: '',
    };

    validateForm() {
        return (
            !this.state.isLoading &&
            emailValidation(this.state.email) &&
            this.state.code &&
            this.state.password.length >= 6
        );
    }

    handlechange = (event: any) => {
        this.setState({ [event.target.id]: event.target.value } as any);
    };

    handleBackButton = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    handleSubmit = (event: any) => {
        event.preventDefault();
        const { email, code, password } = this.state;

        if (password.length === 0) {
            this.setState({ responseError: 'Please provide a NEW password' });
        } else if (password.length < 6) {
            this.setState({ responseError: 'Password must be at least 6 characters long' });
        } else if (email) {
            this.setState({ isLoading: true }, async () => {
                try {
                    const userService = new UserService();
                    await userService.resetPassword(email.toLowerCase(), code, password);
                    this.setState({ isLoading: false, showConfirmation: true });
                } catch (e) {
                    this.setState({ responseError: (e as any).message, isLoading: false });
                }
            });
        } else {
            this.setState({ responseError: INVALID_EMAIL_ERROR });
        }
    };

    goToSignIn = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    goToDeepLink = () => {
        setTimeout(function () {
            window.location.href = 'https://my5.tv';
        }, 25);
        window.location.href = process.env.REACT_APP_DEEPLINK || '';
    };

    getForm = () => {
        const { email, password, responseError } = this.state;
        return (
            <Container>
                <div className={style.changePasswordScreen}>
                    <div className={style.changePasswordHeader}>
                        <h2 className={style.heading}>Change Password</h2>
                        <button
                            className={style.backIcon}
                            onClick={this.handleBackButton}
                            data-testid="back-icon"
                            id="back-btn"
                        />
                    </div>
                    <div className={style.formContainer}>
                        <form onSubmit={this.handleSubmit} className={style.formContent}>
                            <InputField
                                inputtype="email"
                                inputvalue={email}
                                labeltext="Email address: *"
                                inputid="email"
                                handlechange={this.handlechange}
                            />
                            <InputField
                                inputtype="password"
                                inputvalue={password}
                                labeltext="New password: *"
                                inputid="password"
                                showHide={true}
                                handlechange={this.handlechange}
                                error={responseError}
                            />
                            <FormButton
                                disabled={!this.validateForm()}
                                buttonType="submit"
                                buttonText="Submit New Password"
                                buttonId="submit-form"
                            />
                        </form>
                    </div>
                </div>
            </Container>
        );
    };

    getConfirmation = () => {
        return (
            <Container className={style.confirmationScreen}>
                <div className={style.confirmationText}>
                    <div className={style.checkIcon}></div>
                    <div className={style.header}>Thanks!</div>
                    <div className={style.note}>Your 5 password has now been reset.</div>
                    <div className={style.note}>You can now use this to sign in.</div>
                </div>
                <div className={style.buttons}>
                    <FormButton
                        buttonType="submit"
                        buttonText="Activate PIN shown on TV"
                        buttonId={'activate-pin-tv'}
                        disabled={false}
                        onClick={this.goToSignIn}
                    />
                    <FormButton
                        buttonType="submit"
                        type="ghost"
                        buttonText="Continue to 5"
                        buttonId={'continue-to-5'}
                        disabled={false}
                        onClick={this.goToDeepLink}
                    />
                </div>
            </Container>
        );
    };

    render() {
        const { showConfirmation } = this.state;
        return showConfirmation ? this.getConfirmation() : this.getForm();
    }
}
