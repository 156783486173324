/* eslint-disable @typescript-eslint/no-explicit-any */
import { getEnvName, getEnvPath } from './env';

const platform = 'my5desktop';

export default class UserService {
    userService: any;

    constructor() {
        const url = process.env.REACT_APP_USERSERVICE_URL;
        const options = {
            apiUrl: url ? url.replace('{env}', getEnvPath()) : '',
            env: getEnvName(),
            platform: platform,
        };
        this.userService = new window.c5UserService.UserService(options);
    }

    getVersion = (): string => {
        return this.userService.getVersion();
    };

    signUp = (
        email: string,
        password: string,
        givenName: string,
        familyName: string,
        birthDate: string,
        postCode: string
    ): Promise<any> => {
        return this.userService.authenticationService
            .signUp(email, password, givenName, familyName, birthDate, postCode)
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    signIn = (email: string, password: string, needSync: boolean = false): Promise<any> => {
        return this.userService.authenticationService
            .signIn(email, password, needSync)
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    signOut = (): Promise<boolean> => {
        return this.userService.authenticationService
            .signOut()
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    forgotPassword = (email: string): Promise<void> => {
        return this.userService.authenticationService
            .forgotPassword(email)
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    resetPassword = (email: string, code: string, password: string): Promise<void> => {
        return this.userService.authenticationService
            .resetPassword(email, code, password)
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    activatePin = (pin: string): Promise<void> => {
        return this.userService.pinPairingService
            .activatePin(pin)
            .then((result: any) => {
                return result;
            })
            .catch((error: any) => {
                return error;
            });
    };

    getUserInfo = async (): Promise<any> => {
        try {
            return await this.userService.authenticationService.userInfo();
        } catch (ex) {
            return null;
        }
    };
    emailValidation = (email: string): Promise<any> => {
        return this.userService.authenticationService.validateEmail(email.toLowerCase());
    };
}
