import React from 'react';
import './Tooltip.scss';
interface TooltipProps {
    tooltipText: string;
    header: string;
}

class Tooltip extends React.Component<TooltipProps> {
    state = {
        tooltipOpen: false,
    };

    _toggleTooltip = () => {
        const { tooltipOpen } = this.state;
        this.setState({
            tooltipOpen: !tooltipOpen,
        });
    };
    render() {
        const { tooltipText, header } = this.props;
        const { tooltipOpen } = this.state;
        return (
            <>
                <input className="icon tooltip" type="button" onClick={this._toggleTooltip} />
                <div className={`tooltip-content ${tooltipOpen ? 'visible' : 'hidden'}`}>
                    <h2 className="header">{header}</h2>
                    <div className="content">{tooltipText}</div>
                    <button aria-label="close" className="icon close" type="button" onClick={this._toggleTooltip} />
                </div>
            </>
        );
    }
}

export default Tooltip;
