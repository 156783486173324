import React, { Component } from 'react';

import FormButton from '../../components/FormButton/FormButton';
import InputField from '../../components/InputField/InputField';
import { Screens, USER_ERROR_INCORRECT, INVALID_EMAIL_ERROR } from '../../containers/constants';
import { setTrackingEvent } from 'utils';
import UserService from 'utils/userService';

import './Signin.scss';
import FormError from 'components/FormError/FormError';

interface ISigninProps {
    handleScreen: Function;
    handleUserUpdate: Function;
}
export default class Signin extends Component<ISigninProps> {
    state = {
        isLoading: false,
        email: '',
        password: '',
        responseError: '',
        isCompleted: false,
        emailError: '',
    };

    componentDidMount() {
        setTrackingEvent('signinStart', 'signin', { trigger: 'NO_TRIGGER' });
    }

    componentWillUnmount() {
        const { email, password, isCompleted } = this.state;
        const filledFields = `${email ? 'email' + (password && '|') : ''}${password ? 'password' : ''}`;
        !isCompleted && setTrackingEvent('signinAbandon', 'signin', { filledFields });
    }

    validateForm() {
        return !this.state.isLoading && this.state.email.length > 0 && this.state.password.length > 0;
    }
    handleBlur = (event: any) => {
        if (event.target.id === 'email' && event.target.value) {
            if (
                !/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    event.target.value
                )
            ) {
                this.setState({ emailError: 'Invalid email address format' });
            } else {
                this.setState({ emailError: '' });
            }
        }
    };
    handlechange = (event: any) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleForgotPass = () => {
        this.props.handleScreen(Screens.FORGOT_PASSWORD);
    };

    handleSocialLogin = () => {
        this.props.handleScreen(Screens.SOCIAL_LOGIN);
    };

    handleRegister = () => {
        this.props.handleScreen(Screens.REGISTER);
    };

    handleSubmit = async (event: any) => {
        event.preventDefault();
        const { email, password } = this.state;

        if (email) {
            this.setState({ isLoading: true }, async () => {
                try {
                    const userService = new UserService();
                    const result = await userService.signIn(email.toLowerCase(), password);

                    if (!result || result.code) {
                        this.setState({
                            responseError: result ? result.message : 'Invalid email address or password',
                            isLoading: false,
                        });
                        setTrackingEvent('signinError', 'signin', {
                            errorCode: result ? result.code : 'Missing token',
                        });
                    } else {
                        const userInfo = await userService.getUserInfo();
                        await this.props.handleUserUpdate();
                        setTrackingEvent('signinComplete', 'signin', {
                            profileID: userInfo.userId,
                            regUsrAgeBracket: userInfo.ageBracket,
                            loggedInStatus: 'logged in',
                            gender: 'unknown',
                        });

                        this.setState({ isLoading: false, isCompleted: true }, () =>
                            this.props.handleScreen(Screens.PIN_SCREEN)
                        );
                    }
                } catch (e) {
                    const message =
                        (e as any).code === 'UserNotFoundException' ? USER_ERROR_INCORRECT : (e as any).message;
                    const code = (e as any).code;
                    setTrackingEvent('signinError', 'signin', { errorCode: `${code} (${code})` });

                    this.setState({ responseError: message, isLoading: false });
                }
            });
        } else {
            setTrackingEvent('signinError', 'signin', { errorCode: 'Invalid email address' });
            this.setState({ responseError: INVALID_EMAIL_ERROR });
        }
    };

    render() {
        const { email, password, responseError, isLoading, emailError } = this.state;
        return (
            <form onSubmit={this.handleSubmit} className="signin">
                <InputField
                    inputtype="text"
                    inputvalue={email}
                    labeltext="Email address"
                    inputid="email"
                    handlechange={this.handlechange}
                    blurValidation={this.handleBlur}
                />
                {emailError && <FormError error={emailError} style={{ marginTop: '-20px' }} />}
                <div className="password-container">
                    <InputField
                        inputtype="password"
                        inputvalue={password}
                        labeltext="Password"
                        inputid="password"
                        data-testid="password-input"
                        handlechange={this.handlechange}
                        showHide
                    />
                    <button className="link forgotten-password" onClick={this.handleForgotPass}>
                        Forgot password?
                    </button>
                </div>
                <div className="submit-container">
                    <FormButton
                        disabled={!this.validateForm() || !!emailError}
                        buttonType="submit"
                        buttonText="Sign in"
                        buttonId="submit-form"
                    />
                    {isLoading && (
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    )}
                    {responseError && <FormError error={responseError} />}
                </div>
                <div className="extra-content">
                    <span className="create-account">Want To Create A New Account?</span>
                    <button id="register-link" className="link" onClick={this.handleRegister}>
                        Register Here
                    </button>
                </div>
            </form>
        );
    }
}
