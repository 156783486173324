import React from 'react';

import style from './Container.module.scss'; 

interface ContainerInterface {
    className?: string;
    children: React.ReactNode;
}

const Container: React.FC<ContainerInterface> = ({ className, children }) => {
    return (
        <div className={`${style.container} ${className}`}>
            {children}
        </div>
    );
};

export default Container;
