import React from 'react';

import FormButton from '../../components/FormButton/FormButton';
import InputField from '../../components/InputField/InputField';
import { Screens } from 'containers/constants';

import './ForgotPassword.scss';
import UserService from 'utils/userService';
import MailSent from './MailSent';
import FormError from 'components/FormError/FormError';
import { emailValidation } from 'utils';

const ERROR_USER_NOT_FOUND = 'UserNotFoundException';
interface ForgotPasswordProps {
    socialLogin: boolean;
    handleScreen: Function;
}
export default class ForgotPassword extends React.Component<ForgotPasswordProps> {
    state = {
        isLoading: false,
        email: '',
        responseError: '',
        sent: false,
    };

    validateForm() {
        return !this.state.isLoading && this.state.email.length > 0 && emailValidation(this.state.email);
    }

    handlechange = (event: any) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleBackButton = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    handleSubmit = (event: any) => {
        event.preventDefault();

        if (this.state.email) {
            this.setState({ isLoading: true }, async () => {
                try {
                    const userService = new UserService();
                    const result = await userService.forgotPassword(this.state.email.toLowerCase());
                    if ((result as any).code === ERROR_USER_NOT_FOUND) {
                        throw result;
                    }
                    this.setState({ sent: true, responseError: '', isLoading: false });
                } catch (e) {
                    console.log('error', e);
                    this.setState({ responseError: (e as any).message, isLoading: false });
                }
            });
        } else {
            this.setState({ responseError: 'Invalid email address' });
        }
    };

    handleClose = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    render() {
        const { email, sent, responseError } = this.state;
        const { socialLogin } = this.props;

        return (
            <div className="forgot-password-screen">
                {sent ? (
                    <MailSent email={this.state.email} close={() => this.props.handleScreen(Screens.SIGNIN)} />
                ) : (
                    <>
                        <div className="forgot-password-header">
                            <h2 className="heading">{socialLogin ? 'Social Account' : 'Forgotten Password'}</h2>
                            <button
                                data-testid="back-btn"
                                className="back-icon"
                                aria-label="Go back"
                                onClick={this.handleBackButton}
                            />
                        </div>
                        <form onSubmit={this.handleSubmit} className="form-content">
                            {socialLogin && (
                                <div className="social-login-info">
                                    <div>
                                        The way you sign into 5 is changing. We’re removing the option to sign in with
                                        your social media account.
                                    </div>
                                    <div>
                                        To update your 5 account, please enter your email below and we will send you
                                        instructions of how to set a password.
                                    </div>
                                </div>
                            )}
                            <InputField
                                inputtype="email"
                                inputvalue={email}
                                labeltext="Email address"
                                inputid="email"
                                handlechange={this.handlechange}
                            />
                            {responseError && <FormError error={responseError} />}
                            <FormButton
                                disabled={!this.validateForm()}
                                buttonType="submit"
                                buttonText="Send Details"
                                buttonId="submit-form"
                            />
                        </form>
                    </>
                )}
            </div>
        );
    }
}
